import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { userLogoutAction } from './userReducer';

export interface CompanyState extends Company {
  status?: string | undefined;
  loaded?: boolean;
}

export enum FVerificationStatusEnum {
  NONE = '未提交申请',
  SUBMITTED = '已提交申请',
  VERIFIED = '已通过审核',
  DENIED = '未通过审核',
}

const initialState = {
  id: undefined,
  user: undefined,
  companyName: undefined,
  avatar: undefined,
  contact: undefined,
  numberPhone: undefined,
  industry: undefined,
  registrationAddress: undefined,
  contactAddress: undefined,
  verification: undefined,
  loaded: false,
  deleted: undefined,
  status: undefined,
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    companyOutAction: () => initialState,
    companyKnownAction: (state: CompanyState, action: PayloadAction<CompanyState>) => {
      state.loaded = action.payload.loaded || false;
      state.id = action.payload.id;
      state.user = action.payload.user;
      state.status = action.payload.verification?.status;
      state.deleted = action.payload.deleted;
    },
    companyVerificationKnownAction: (
      state: CompanyState,
      action: PayloadAction<CompanyVerification>
    ) => {
      const verification = action.payload;
      delete verification.__typename;
      state.verification = verification;
      const basicInfo = action.payload.basicInfo;
      delete basicInfo?.__typename;
      state.verification.basicInfo = basicInfo;
      const otherInfo = action.payload.supplementInfo;
      delete otherInfo?.__typename;
      otherInfo?.certificateFileList && otherInfo?.certificateFileList.length > 0
        ? otherInfo?.certificateFileList.map(f => delete f.__typename)
        : '';
      state.verification.supplementInfo = otherInfo;
    },

    [userLogoutAction.type]: () => initialState,
  },
});

export const {
  companyKnownAction,
  companyVerificationKnownAction,
  companyOutAction,
} = companySlice.actions;

export default companySlice.reducer;
