import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { userLogoutAction } from './userReducer';

export interface CompanyRequestListState {
  projectList: ProjectRequestInfo[];
}

const initialState: CompanyRequestListState = {
  projectList: [],
};

const companyRequestListSlice = createSlice({
  name: 'companyRequest',
  initialState,
  reducers: {
    companyRequestListKnownAction: (
      state: CompanyRequestListState,
      action: PayloadAction<ProjectRequestInfo[]>
    ) => {
      state.projectList = action.payload;
    },
    [userLogoutAction.type]: () => initialState,
  },
});

export const { companyRequestListKnownAction } = companyRequestListSlice.actions;

export default companyRequestListSlice.reducer;
