import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import admin, { AdminState } from './adminReducer';
import companyAllProjectBid, { CompanyAllProjectBidState } from './companyAllProjectBid';
import companyMenuReducer, { companyMenuState } from './companyMenuReducer';
import company, { CompanyState } from './companyReducer';
import consultantAllProjectBid, { consultantAllProjectBidState } from './consultantAllProjectBid';
import consultantFilter, { filterDataType } from './consultantFilter';
import consultantHomePage, { ConsultantHomePageState } from './consultantHomePageReducer';
import consultant, { ConsultantState } from './consultantReducer';
import loading, { LoadingState } from './loadingReducer';
import notification, { NotificationState } from './notificationReducer';
import consultantVerificationStep, { ConsultantVerificationStepType } from './otherStatus';
import projectListReducer, { CompanyRequestListState } from './projectListReducer';
import projectProduce, { projectProduceInfoState } from './projectProduceListReducer';
import companyRequest, { CompanyRequestState } from './projectRequestReducer';
import routerControlReducer, { RouterControlState } from './routerControlReducer';
import user, { UserState } from './userReducer';
import version, { VersionState } from './versionReducer';

export interface AppState {
  version: VersionState;
  user: UserState;
  admin: AdminState;
  company: CompanyState;
  loading: LoadingState;
  notification: NotificationState;
  consultant: ConsultantState;
  consultantHomePage: ConsultantHomePageState;
  consultantFilter: filterDataType;
  consultantVerificationStep: ConsultantVerificationStepType;
  companyRequest: CompanyRequestState;
  projectListReducer: CompanyRequestListState;
  companyAllProjectBid: CompanyAllProjectBidState;
  consultantAllProjectBid: consultantAllProjectBidState;
  projectProduce: projectProduceInfoState;
  companyMenuReducer: companyMenuState;
  routerControlReducer: RouterControlState;
}

export const store = configureStore({
  reducer: {
    version,
    user,
    admin,
    company,
    loading,
    notification,
    consultant,
    consultantHomePage,
    consultantFilter,
    consultantVerificationStep,
    companyRequest,
    projectListReducer,
    companyAllProjectBid,
    consultantAllProjectBid,
    projectProduce,
    companyMenuReducer,
    routerControlReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

const reducer = combineReducers({
  version,
  user,
  admin,
  company,
  loading,
  notification,
  consultant,
  consultantHomePage,
  consultantFilter,
  consultantVerificationStep,
  companyRequest,
  projectListReducer,
  companyAllProjectBid,
  consultantAllProjectBid,
  projectProduce,
  companyMenuReducer,
  routerControlReducer,
});

export default reducer;
