import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { userLogoutAction } from './userReducer';

export interface AdminState {
  id?: number;
  loaded?: boolean;
  companyList: Company[];
}

const initialState = {
  id: undefined,
  loaded: false,
  companyList: [],
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    [userLogoutAction.type]: () => initialState,
    adminKnownAction: (state: AdminState, action: PayloadAction<AdminState>) => {
      state.loaded = action.payload.loaded || false;
      state.id = action.payload.id;
    },
    companyListKnownAction: (state: AdminState, action: PayloadAction<Company[]>) => {
      state.companyList = action.payload;
    },
  },
});

export const { adminKnownAction, companyListKnownAction } = adminSlice.actions;

export default adminSlice.reducer;
