import { Color } from '@material-ui/lab/Alert';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from '.';

export interface NotificationState {
  isVisible: boolean;
  severity?: Color;
  message?: string;
}

const initialState = {
  isVisible: false,
  severity: undefined,
  message: undefined,
};

type NotificationItem = {
  severity: Color;
  message: string;
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    showNotificationAction: (state: NotificationState, action: PayloadAction<NotificationItem>) => {
      state.isVisible = true;
      state.severity = action.payload.severity;
      state.message = action.payload.message;
    },
    hideNotificationAction: (state: NotificationState) => {
      state.isVisible = false;
    },
  },
});

export const { showNotificationAction, hideNotificationAction } = notificationSlice.actions;

export const getNotification = (state: AppState): NotificationState => state.notification;

export default notificationSlice.reducer;
