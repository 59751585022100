import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface companyMenuState {
  menuStatus: CompanyMenu | undefined;
}

const initialState = {
  menuStatus: undefined,
};

const companyMenuSlice = createSlice({
  name: 'companyMenu',
  initialState,
  reducers: {
    companyMenuAction: (state: companyMenuState, action: PayloadAction<companyMenuState>) => {
      state.menuStatus = action.payload.menuStatus;
    },
  },
});

export const { companyMenuAction } = companyMenuSlice.actions;

export default companyMenuSlice.reducer;
