import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface filterDataType {
  state: {};
  addressState: [];
  industryArr: [];
  specialArr: [];
  educationState: {};
  languageState: {};
  workModeState: {};
  workingYear: {};
  workYearValueStart: string;
  worYearValueEnd: string;
  ageSizeState: {};
  ageStart: string;
  ageEnd: string;
  selectedValue: string;
}
const initialState = {
  //全选
  state: {
    SelectAllIndustries: true,
    SelectAllMajors: true,
    SelectAllWorkYear: true,
    SelectAllEducational: true,
    SelectAllLanguages: true,
    SelectAllWorkMode: true,
    SelectAllAge: true,
    SelectAllAddresses: true,
  },
  // 工作地点
  addressState: [],
  // 行业
  industryArr: [],
  // 专业
  specialArr: [],
  // 教育
  educationState: {
    JuniorHighSchoolBelow: false,
    HighschoolTechnicalSecondarySchool: false,
    juniorCollege: false,
    undergraduateEducation: false,
    master: false,
    doctoralDegree: false,
    // xl7: false,
  },
  // 语言
  languageState: {
    english: false,
    chinese: false,
    French: false,
    Spanish: false,
    Norwegian: false,
  },
  // 工作模式
  workModeState: {
    onlineWork: false,
    offlineWork: false,
  },
  // 工作年限
  workingYear: {
    work1to3: false,
    work3to5: false,
    work5to8: false,
    work8to15: false,
    workAbove15: false,
  },
  // 开始工作
  workYearValueStart: '',
  // 结束工作
  worYearValueEnd: '',
  // 年龄
  ageSizeState: {
    Below25: false,
    age25to30: false,
    age30to40: false,
    age40to50: false,
    Above50: false,
  },
  // 起始年龄
  ageStart: '',
  // 结束年龄
  ageEnd: '',
  //   工作状态
  selectedValue: 'a',
};
const filterData = createSlice({
  name: 'consultantFilter',
  initialState,
  reducers: {
    setFilter: (state: filterDataType, action: PayloadAction<filterDataType>) => {
      state.selectedValue = action.payload.selectedValue;
      state.ageEnd = action.payload.ageEnd;
      state.ageStart = action.payload.ageStart;
      state.ageSizeState = action.payload.ageSizeState;
      state.worYearValueEnd = action.payload.worYearValueEnd;
      state.workYearValueStart = action.payload.workYearValueStart;
      state.workingYear = action.payload.workingYear;
      state.workModeState = action.payload.workModeState;
      state.languageState = action.payload.languageState;
      state.educationState = action.payload.educationState;
      state.specialArr = action.payload.specialArr;
      state.industryArr = action.payload.industryArr;
      state.addressState = action.payload.addressState;
      state.state = action.payload.state;
    },
  },
});

export const { setFilter } = filterData.actions;

export default filterData.reducer;
