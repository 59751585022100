import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { userLogoutAction } from './userReducer';

export interface ConsultantState extends Consultant {
  loaded?: boolean;
}

export enum ConsultantStatusEnum {
  NONE = '未提交申请',
  SUBMITTED = '已提交申请',
  VERIFIED = '已通过审核',
  DENIED = '未通过审核',
  PERSONAL_REGISTERED = '',
  EDUCATION_REGISTERED = '',
  WORK_REGISTERED = '',
  PROJECT_REGISTERED = '',
  LANGUAGE_REGISTERED = '',
  SETTINGS_REGISTERED = '',
}

const initialState = {
  id: undefined,
  user: undefined,
  firstName: undefined,
  lastName: undefined,
  avatar: undefined,
  phoneNumber: undefined,
  nowStatus: undefined,
  interestedIndustry: undefined,
  interestedPosition: undefined,
  description: undefined,
  deleted: undefined,
  verification: undefined,
  loaded: false,
};

const consultantSlice = createSlice({
  name: 'consultant',
  initialState,
  reducers: {
    consultantKnownAction: (state: ConsultantState, action: PayloadAction<ConsultantState>) => {
      state.loaded = action.payload.loaded || false;
      state.id = action.payload.id;
      state.user = action.payload.user;
      // state.firstName = action.payload.firstName;
      // state.lastName = action.payload.lastName;
      // state.avatar = action.payload.avatar;
      // state.phoneNumber = action.payload.phoneNumber;
      // state.nowStatus = action.payload.nowStatus;
      // state.interestedIndustry = action.payload.interestedIndustry;
      // state.interestedPosition = action.payload.interestedPosition;
      state.verification = action.payload.verification;
      state.deleted = action.payload.deleted;
      state.description = action.payload.description;
    },
    consultantVerificationKnownAction: (
      state: ConsultantState,
      action: PayloadAction<ConsultantVerification>
    ) => {
      const verification = action.payload;
      delete verification.__typename;
      state.verification = verification;

      const basicInfo = action.payload.basicInfo;
      delete basicInfo?.__typename;
      basicInfo?.personalDescriptionFileList && basicInfo?.personalDescriptionFileList.length > 0
        ? basicInfo?.personalDescriptionFileList.map(f => delete f.__typename)
        : '';
      state.verification.basicInfo = basicInfo;

      const educationInfoList = action.payload.educationInfoList;
      educationInfoList?.map(e => delete e.__typename);
      state.verification.educationInfoList = educationInfoList;

      const workInfoList = action.payload.workInfoList;
      workInfoList?.map(e => delete e.__typename);
      state.verification.workInfoList = workInfoList;

      const projectInfoList = action.payload.projectInfoList;
      projectInfoList?.map(e => {
        delete e.__typename;
        e.projectImageFileList ? e.projectImageFileList.map(f => delete f.__typename) : '';
      });
      state.verification.projectInfoList = projectInfoList;

      const languageInfoList = action.payload.languageInfoList;
      languageInfoList?.map(e => {
        delete e.__typename;
      });
      state.verification.projectInfoList = projectInfoList;

      const honorInfoList = action.payload.honorInfoList;
      honorInfoList?.map(e => delete e.__typename);
      state.verification.honorInfoList = honorInfoList;

      const certificateInfoList = action.payload.certificateInfoList;
      certificateInfoList?.map(e => {
        delete e.__typename;
        e.certificateFileList.map(f => delete f.__typename);
      });
      state.verification.certificateInfoList = certificateInfoList;

      const otherInfo = action.payload.otherInfo;
      delete otherInfo?.__typename;
      state.verification.otherInfo = otherInfo;

      const settings = action.payload.settings;
      delete settings?.__typename;
      typeof settings?.workScenario == 'string'
        ? (settings.workScenario = JSON.parse(settings.workScenario))
        : '';
      state.verification.settings = settings;
    },

    consultantPrevStateAction: (
      state: ConsultantState,
      action: PayloadAction<ConsultantVerification>
    ) => {
      const verification = action.payload;
      delete verification.__typename;
      state.verification = action.payload;

      const basicInfo = action.payload.basicInfo;
      // delete basicInfo?.__typename;
      basicInfo?.personalDescriptionFileList?.map(f => delete f.__typename);
      state.verification.basicInfo = basicInfo;

      const educationInfoList = action.payload.educationInfoList;
      educationInfoList?.map(e => delete e.__typename);
      state.verification.educationInfoList = educationInfoList;

      const workInfoList = action.payload.workInfoList;
      workInfoList?.map(e => delete e.__typename);
      state.verification.workInfoList = workInfoList;

      const projectInfoList = action.payload.projectInfoList;
      projectInfoList?.map(e => {
        delete e.__typename;
        e.projectImageFileList && e.projectImageFileList.length > 0
          ? e.projectImageFileList.map(f => delete f.__typename)
          : '';
      });
      state.verification.projectInfoList = projectInfoList;

      const languageInfoList = action.payload.languageInfoList;
      languageInfoList?.map(f => delete f.__typename);
      state.verification.languageInfoList = languageInfoList;

      const honorInfoList = action.payload.honorInfoList;
      honorInfoList?.map(e => delete e.__typename);
      state.verification.honorInfoList = honorInfoList;

      const certificateInfoList = action.payload.certificateInfoList;
      certificateInfoList?.map(e => {
        delete e.__typename;
        e.certificateFileList.map(f => delete f.__typename);
      });
      state.verification.certificateInfoList = certificateInfoList;

      const otherInfo = action.payload.otherInfo;
      delete otherInfo?.__typename;
      state.verification.otherInfo = otherInfo;

      const settings = action.payload.settings;
      delete settings?.__typename;
      typeof settings?.workScenario == 'string'
        ? (settings.workScenario = JSON.parse(settings.workScenario))
        : '';
      state.verification.settings = settings;
      state.verification.consultantType = action.payload.consultantType;
      state.verification.status = action.payload.status;
      state.verification.consultantId = action.payload.consultantId;
      state.verification.id = action.payload.id;
    },
    [userLogoutAction.type]: () => initialState,
  },
});

export const {
  consultantKnownAction,
  consultantVerificationKnownAction,
  consultantPrevStateAction,
} = consultantSlice.actions;
export default consultantSlice.reducer;
