import { createSlice } from '@reduxjs/toolkit';
export interface ConsultantVerificationStepType {
  ConsultantVerificationStep: consultantRegisterStepType;
  showCompanyVerification: boolean;
}
const initialState = {
  ConsultantVerificationStep: 'NONE',
  showCompanyVerification: false,
};
const ConsultantVerificationStepActive = createSlice({
  name: 'ConsultantVerificationStep',
  initialState,
  reducers: {
    activeConsultantVerificationStep: (state, action) => {
      state.ConsultantVerificationStep = action.payload.ConsultantVerificationStep;
    },
    activeCompanyVerificationRegister: (state, action) => {
      state.showCompanyVerification = action.payload.showCompanyVerification;
    },
  },
});

export const {
  activeConsultantVerificationStep,
  activeCompanyVerificationRegister,
} = ConsultantVerificationStepActive.actions;

export default ConsultantVerificationStepActive.reducer;
