import { createSlice } from '@reduxjs/toolkit';

import { userLogoutAction } from './userReducer';

export interface LoadingState {
  isLoading: boolean;
}

const initialState = {
  isLoading: false,
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    loadingStartAction: (state: LoadingState) => {
      state.isLoading = true;
    },
    loadingEndAction: (state: LoadingState) => {
      state.isLoading = false;
    },
    [userLogoutAction.type]: () => initialState,
  },
});

export const { loadingStartAction, loadingEndAction } = loadingSlice.actions;

export default loadingSlice.reducer;
