import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { userLogoutAction } from './userReducer';

export interface projectProduceInfoState {
  projectProduceInfo: ProjectInfo[];
}

const initialState: projectProduceInfoState = {
  projectProduceInfo: [],
};

const ProjectInfoListSlice = createSlice({
  name: 'companyRequest',
  initialState,
  reducers: {
    ProjectInfoListKnownAction: (
      state: projectProduceInfoState,
      action: PayloadAction<ProjectInfo[]>
    ) => {
      state.projectProduceInfo = action.payload;
    },
    [userLogoutAction.type]: () => initialState,
  },
});

export const { ProjectInfoListKnownAction } = ProjectInfoListSlice.actions;

export default ProjectInfoListSlice.reducer;
