import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
// import { format } from 'date-fns';
// import firebase from 'gatsby-plugin-firebase';
import { ASTNode } from 'graphql';
// import { print } from 'graphql/language/printer';
import gql from 'graphql-tag';
import fetch from 'isomorphic-fetch';

import debug from '../utils/debug';

export const TOKEN_KEY = 'fintegrity-api:token';
export const UID_KEY = 'AI47jgK&8gj';

const API_URL_ENV = process.env.GATSBY_API_URL;
// export const API_URL = API_URL_ENV || 'https://api.fintegrity.cn/graphql';
export const API_URL = API_URL_ENV || 'http://web.fintegrity.cn:8090/graphql';
const ERROR_CODE_REGEX = /^Exception while fetching data \S* : (\d*):.*$/;

/**
 * Parse error message from the complete error message
 * @param {string} errorMessage the complete error message
 * @returns {string | undefined} the error code parsed from the complete error message
 */
const parseErrorCode = (errorMessage: string): number | undefined => {
  const found = errorMessage.match(ERROR_CODE_REGEX);
  return found ? parseInt(found[1]) : undefined;
};

export const getToken = (): string | null | undefined =>
  typeof localStorage !== 'undefined' ? localStorage.getItem(TOKEN_KEY) : undefined;

export const removeToken = (): void => {
  if (typeof localStorage !== 'undefined') localStorage.removeItem(TOKEN_KEY);
};

export const client = (): ApolloClient<NormalizedCacheObject> =>
  new ApolloClient({
    link: ApolloLink.from([
      new HttpLink({
        uri: API_URL,
        fetch,
        headers: {
          authorization: getToken(),
        },
      }),
    ]),
    cache: new InMemoryCache(),
  });

/**
 * GraphQL query call function
 * This function is used only when useQuery is not sufficient.
 * Token is already set for the request so just simply call it to request data.
 * @param {string} gqlString GraphQL string defined in graphqlString.tx
 * @param {object} variables variables carried in the query call
 * @returns {Promise} promise with data and error
 */
export const query: Function = (gqlString: ASTNode, variables: object = {}) =>
  new Promise(resolve => {
    // const requestStartMills = new Date().getTime();
    client()
      .query({
        query: gql`
          ${gqlString}
        `,
        variables,
        fetchPolicy: 'no-cache',
      })
      .then(result => {
        // log2Firebase(
        //   gqlString as ASTNode,
        //   variables,
        //   { hidden: '**' },
        //   {},
        //   new Date().getTime() - requestStartMills
        // );
        if (result && result.data) {
          debug('Query result', result.data);
          resolve({ data: result.data });
        }
      })
      .catch(error => {
        // log2Firebase(
        //   gqlString as ASTNode,
        //   variables,
        //   {},
        //   error,
        //   new Date().getTime() - requestStartMills
        // );
        debug('Query error', error.message || error);
        resolve({
          data: undefined,
          error: { ...error, code: parseErrorCode(error.message || error) },
        });
      });
  });

/**
 * GraphQL mutate call function
 * This function is used only when useQuery is not sufficient.
 * Token is already set for the request so just simply call it to request data.
 * @param {string} gqlString GraphQL string defined in graphqlString.tx
 * @param {object} variables variables carried in the query call
 * @returns {Promise} promise with data and error
 */
export const mutate: Function = (gqlString: ASTNode, variables: object = {}) => {
  // const requestStartMills = new Date().getTime();
  return new Promise(resolve => {
    client()
      .mutate({
        mutation: gql`
          ${gqlString}
        `,
        variables,
        fetchPolicy: 'no-cache',
      })
      .then(result => {
        // log2Firebase(
        //   gqlString as ASTNode,
        //   variables,
        //   result.data,
        //   {},
        //   new Date().getTime() - requestStartMills
        // );
        if (result && result.data) {
          debug('Mutate result', result.data);
          resolve({ data: result.data });
        }
      })
      .catch(error => {
        // log2Firebase(
        //   gqlString as ASTNode,
        //   variables,
        //   {},
        //   error,
        //   new Date().getTime() - requestStartMills
        // );
        debug('Mutate error', error.message || error);
        resolve({
          data: undefined,
          error: { ...error, code: parseErrorCode(error.message || error) },
        });
      });
  });
};
