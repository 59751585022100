import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { userLogoutAction } from './userReducer';

export interface VersionState {
  appVersion: string;
  apiVersion: string;
}

const initialState = {
  appVersion: '',
  apiVersion: '',
};

const versionSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    appVersionKnownAction: (state: VersionState, action: PayloadAction<string>) => {
      state.appVersion = action.payload;
    },
    apiVersionKnownAction: (state: VersionState, action: PayloadAction<string>) => {
      state.apiVersion = action.payload;
    },
    [userLogoutAction.type]: () => initialState,
  },
});

export const { appVersionKnownAction, apiVersionKnownAction } = versionSlice.actions;

export default versionSlice.reducer;
