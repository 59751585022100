import Debug from 'debug';

const _debug = Debug('fintegrity-platform-web');

const debug = (prefix: string, msg?: unknown): void => {
  const _msg = msg && typeof msg === 'object' ? JSON.stringify(msg, null, 2) : msg;
  if (msg) _debug(`${prefix}:\n${_msg}`);
  else _debug(prefix);
};

export default debug;
