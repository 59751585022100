import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { userLogoutAction } from './userReducer';

export interface consultantAllProjectBidState {
  consultantProjectBidList: Bidding_info[];
}

const initialState: consultantAllProjectBidState = {
  consultantProjectBidList: [],
};

const consultantAllProjectBidListSlice = createSlice({
  name: 'companyRequest',
  initialState,
  reducers: {
    consultantAllProjectBidListKnownAction: (
      state: consultantAllProjectBidState,
      action: PayloadAction<Bidding_info[]>
    ) => {
      state.consultantProjectBidList = action.payload;
    },
    [userLogoutAction.type]: () => initialState,
  },
});

export const { consultantAllProjectBidListKnownAction } = consultantAllProjectBidListSlice.actions;

export default consultantAllProjectBidListSlice.reducer;
