import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TOKEN_KEY, UID_KEY } from '../utils/graphql';

export interface UserState {
  userType?: FUserTypeEnum;
  id?: number;
  token?: string;
  status?: string;
  email?: string;
  emailActivated: boolean;
  phone?: string;
  phoneActivated: boolean;
  admin?: AdminState;
  company?: Company;
  consultant?: Consultant;
}

export interface AdminState {
  id?: number;
}

export enum FUserTypeEnum {
  COMPANY = 'COMPANY',
  CONSULTANT = 'CONSULTANT',
  ADMIN = 'ADMIN',
}

const initialState = {
  userType: undefined,
  id: undefined,
  token: undefined,
  email: undefined,
  emailActivated: false,
  phone: undefined,
  phoneActivated: false,
  status: undefined,
  company: undefined,
  consultant: undefined,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userLogoutAction: () => initialState,
    userTokenKnowAction: (state: UserState, action: PayloadAction<string>) => {
      state.token = action.payload;
      localStorage.setItem(TOKEN_KEY, action.payload);
    },
    userKnownAction: (state: UserState, action: PayloadAction<UserState>) => {
      localStorage.setItem(UID_KEY, (action.payload.id as unknown) as string);
      state.id = action.payload.id;
      state.email = action.payload.email;
      state.emailActivated = action.payload.emailActivated;
      state.company = action.payload.company;
      state.consultant = action.payload.consultant;
      if (state.userType == 'COMPANY') {
        state.status = action.payload.company?.verification?.status;
      }
      if (state.userType == 'CONSULTANT') {
        state.status = action.payload.consultant?.verification?.status;
      }
    },
    userTypeKnownAction: (state: UserState, action: PayloadAction<FUserTypeEnum>) => {
      state.userType = action.payload;
    },
  },
});

export const {
  userLogoutAction,
  userTokenKnowAction,
  userKnownAction,
  userTypeKnownAction,
} = userSlice.actions;

export default userSlice.reducer;
