/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
/* eslint react/prop-types: 0 */
/* eslint import/no-extraneous-dependencies: 0 */
/* eslint react/react-in-jsx-scope: 0 */
/* eslint @typescript-eslint/explicit-function-return-type: 0 */
/* eslint import/prefer-default-export: 0 */

import './src/styles/styles.scss';
import {
  ApolloProvider
} from '@apollo/client';
import {
  ThemeProvider
} from '@material-ui/styles';
import React from 'react';
import {
  Provider
} from 'react-redux';
import {
  I18nextProvider
} from 'react-i18next';
import i18n from './i18n';


import {
  FUserTypeEnum,
  userTypeKnownAction
} from './src/reducers/userReducer';
import {
  store
} from './src/reducers';
import {
  fTheme
} from './src/styles/styles';
import debug from './src/utils/debug';
import {
  client
} from './src/utils/graphql';
import {
  appVersionKnownAction
} from './src/reducers/versionReducer';

export const wrapPageElement = ({
  element
}) => {

  const debugMode = process.env.GATSBY_DEBUG;

  console.log('DEBUG MODE:', debugMode);
  if (typeof localStorage !== 'undefined' && debugMode === 'TRUE') {
    localStorage.debug = 'fintegrity-platform-web';
  } else {
    localStorage.removeItem('debug');
  }

  const appVersion = process.env.GATSBY_APP_VERSION || 'version not set';
  console.log('APP_VERSION:', appVersion);
  store.dispatch(appVersionKnownAction(appVersion));

  if (typeof location !== 'undefined') {
    debug(`Serving at ${location.host}`);
    if (location.pathname.startsWith('/admin')) {
      store.dispatch(userTypeKnownAction(FUserTypeEnum.ADMIN));
    } else if (location.pathname.startsWith('/company')) {
      store.dispatch(userTypeKnownAction(FUserTypeEnum.COMPANY));
    } else if (location.pathname.startsWith('/consultant')) {
      store.dispatch(userTypeKnownAction(FUserTypeEnum.CONSULTANT));
    }
  }


  return ( <Provider store = {store} >
    <ThemeProvider theme = {fTheme} >
    <ApolloProvider client = {client()} >
    <I18nextProvider i18n = { i18n} defaultNS = {['index', 'test']} > 
      {element} 
      </I18nextProvider> </ApolloProvider > </ThemeProvider> </Provider >
  )
};