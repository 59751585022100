import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { userLogoutAction } from './userReducer';

export enum FProjectRequestStatusEnum {
  // 已拒绝
  DENIED = 'DENIED',
  // 编辑中
  EDITING = 'EDITING',
  // 已过期
  EXPIRED = 'EXPIRED',
  // 已完成
  FINISHED = 'FINISHED',
  // 无状态
  NONE = 'NONE',
  // 已发布
  PUBLISHED = 'PUBLISHED',
  // 已提交
  SUBMITTED = 'SUBMITTED',
}

export interface CompanyRequestState extends ProjectRequestInfo {
  loaded?: boolean;
}

const initialState = {
  id: undefined,
  company: undefined,
  projectName: undefined,
  projectContent: undefined,
  workLocationProvince: undefined,
  workLocationCity: undefined,
  workLocationDistrict: undefined,
  workLocationAddress: undefined,
  workload: undefined,
  createTime: undefined,
  industry: undefined,
  professionalClass: undefined,
  certificateInfoList: undefined,
  seniority: undefined,
  languageInfoList: undefined,
  workScenario: undefined,
  remunerationInfo: undefined,
  projectStartTime: undefined,
  projectEndTime: undefined,
  lastUpdateTime: undefined,
  status: undefined,
  projectStatus: undefined,
  loaded: false,
  anonymous: undefined,
  projectStartAsap: undefined,
};

const companyRequestSlice = createSlice({
  name: 'companyRequest',
  initialState,
  reducers: {
    companyRequestKnownAction: (
      state: CompanyRequestState,
      action: PayloadAction<ProjectRequestInfo>
    ) => {
      state.id = action.payload.id;
      const company = action.payload.company;
      delete company?.__typename;
      state.company = company;
      state.projectName = action.payload.projectName;
      state.projectContent = action.payload.projectContent;
      state.workLocationProvince = action.payload.workLocationProvince;
      state.workLocationCity = action.payload.workLocationCity;
      state.workLocationDistrict = action.payload.workLocationDistrict;
      state.workLocationAddress = action.payload.workLocationAddress;
      state.workload = action.payload.workload;
      state.createTime = action.payload.createTime;
      state.industry = action.payload.industry;
      state.professionalClass = action.payload.professionalClass;
      state.anonymous = action.payload.anonymous;
      state.projectStartAsap = action.payload.projectStartAsap;
      state.projectLongTerm = action.payload.projectLongTerm;
      state.projectCover = action.payload.projectCover;
      state.label = action.payload.label;
      state.professionalRequirements = action.payload.professionalRequirements;
      state.otherRequirements = action.payload.otherRequirements;
      state.educationalRequirements = action.payload.educationalRequirements;
      const attachmentFileList = action.payload.attachmentFileList;
      attachmentFileList?.map(e => {
        delete e.__typename;
      });
      state.attachmentFileList = attachmentFileList;

      const certificateInfoList = action.payload.certificateInfoList;
      certificateInfoList?.map(e => {
        delete e.__typename;
      });
      state.certificateInfoList = certificateInfoList;

      const languageInfoList = action.payload.languageInfoList;
      languageInfoList?.map(e => {
        delete e.__typename;
      });
      state.languageInfoList = languageInfoList;

      const workScenario = action.payload.workScenario;
      // delete workScenario.__typename;
      state.workScenario = workScenario;

      state.seniority = action.payload.seniority;

      const remunerationInfo = action.payload.remunerationInfo;
      delete remunerationInfo?.__typename;
      state.remunerationInfo = remunerationInfo;

      state.projectStartTime = action.payload.projectStartTime;
      state.projectEndTime = action.payload.projectEndTime;
      state.lastUpdateTime = action.payload.lastUpdateTime;
      state.status = action.payload.status;
      state.projectStatus = action.payload.projectStatus;
    },
    [userLogoutAction.type]: () => initialState,
  },
});

export const { companyRequestKnownAction } = companyRequestSlice.actions;

export default companyRequestSlice.reducer;
