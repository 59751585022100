import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { userLogoutAction } from './userReducer';

export interface CompanyAllProjectBidState {
  companyProjectBidList: Bidding_info[];
}

const initialState: CompanyAllProjectBidState = {
  companyProjectBidList: [],
};

const companyAllProjectBidListSlice = createSlice({
  name: 'companyRequestBid',
  initialState,
  reducers: {
    companyAllProjectBidListKnownAction: (
      state: CompanyAllProjectBidState,
      action: PayloadAction<Bidding_info[]>
    ) => {
      state.companyProjectBidList = action.payload;
    },
    [userLogoutAction.type]: () => initialState,
  },
});

export const { companyAllProjectBidListKnownAction } = companyAllProjectBidListSlice.actions;

export default companyAllProjectBidListSlice.reducer;
