import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface RouterControlState {
  urlHistory: string[];
}

const initialState = {
  urlHistory: [],
};

const routerControlReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    routerControlSetAction: (
      state: RouterControlState,
      action: PayloadAction<RouterControlState>
    ) => {
      state.urlHistory = action.payload.urlHistory;
    },
  },
});

export const { routerControlSetAction } = routerControlReducer.actions;

export default routerControlReducer.reducer;
