import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { userLogoutAction } from './userReducer';

export interface ConsultantHomePageState {
  loaded?: boolean;
  jobRequestList?: JobRequestInfo[];
  hotCompanyList?: HotCompany[];
}

const initialState = {
  jobRequestList: undefined,
  hotCompanyList: undefined,
  loaded: false,
};

const consultantHomePageSlice = createSlice({
  name: 'consultantHomePage',
  initialState,
  reducers: {
    // consultantKnownAction: (
    //   state: ConsultantHomePageState,
    //   action: PayloadAction<ConsultantHomePageState>
    // ) => {
    //   state.loaded = action.payload.loaded || false;
    //   state.jobRequestList = action.payload.jobRequestList;
    // },
    homePageJobRequestInfoKnownAction: (
      state: ConsultantHomePageState,
      action: PayloadAction<ConsultantHomePageState>
    ) => {
      state.jobRequestList = action.payload.jobRequestList;
    },
    homePageCompanyListKnownAction: (
      state: ConsultantHomePageState,
      action: PayloadAction<ConsultantHomePageState>
    ) => {
      state.hotCompanyList = action.payload.hotCompanyList;
    },
    [userLogoutAction.type]: () => initialState,
  },
});

export const {
  // consultantKnownAction,
  homePageCompanyListKnownAction,
  homePageJobRequestInfoKnownAction,
} = consultantHomePageSlice.actions;

export default consultantHomePageSlice.reducer;
